import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorkerRegistration";
import { HashRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { reducers } from "./store/reducers";
import i18n from "services/TranslateService";
import { I18nextProvider } from "react-i18next";
import authService from "services/AuthService";
import Config from "services/ConfigService";
import {
  setUserDetail,
  setOnboardModal,
  setAttachmentPermission,
  setManualExpenseCurrencyPermission,
  setCompaniesList,
} from "store/common/actions";
import { UserDetailMapper } from "infrastructure/mappers";
import { get, find } from "lodash";
import { composeWithDevTools } from "redux-devtools-extension";
import qs from "qs";
import Cookies from "js-cookie";
import {
  getAttachmentPermission,
  getCompanies,
  getCurrentEmployee,
  getManualExpenseCurrencyChange,
  logError,
} from "./services/ApiService";
import api from "./services/interceptor";
import FederationMetadata from "./app/component/federationMetadata/FederationMetadata";

smoothscroll.polyfill();

const handleError = ({
  message,
  filename,
  lineno,
  colno,
  error,
}: ErrorEvent) => {
  logError({
	message: `${message} ${'at ' + window.location}`,
	stacktrace: error ? error.stack : `at ${filename}:${lineno}:${colno}`,
	level: "Error",
  });
};

const handleUncaughtException = (error: PromiseRejectionEvent) => {
  if (error && error.reason) {
	throw new Error(error.reason.message);
  }
};

window.addEventListener("error", handleError);
window.addEventListener("unhandledrejection", handleUncaughtException);

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);
let accessDenied = true;
let widgetMode = false;

const preStart = async () => {
  const currentLocation = window.location.href;
  let url = new URL(currentLocation);
  widgetMode = url.hash.startsWith("#/widget");

  if (!widgetMode) {
    let newLocation = localStorage.getItem("redirectLocation");

    if (newLocation) {
      localStorage.removeItem("redirectLocation");
      newLocation !== currentLocation && window.location.replace(newLocation);
    }
  }

  if (widgetMode) {
    localStorage.setItem("widgetMode", "true");
    let token = qs.parse(
      url.href.slice(url.href.lastIndexOf("?") + 1),
    ).id_token;

    if (token) {
      api.defaults.headers = {
        ...api.defaults.headers,
        Authorization: `Bearer ${token}`,
      };
      api.defaults.headers.common = {
        ...api.defaults.headers.common,
        "Content-Type": "application/json; charset=utf-8",
        "Authorization-Token-Type": "Expense-Corteos",
      };
    }
  } else {
    localStorage.removeItem("widgetMode");
    let user = await authService.getUser();

    if (user) {
      Cookies.set(
        "isLoggedIn",
        process.env.REACT_APP_ENVIRONMENT || "PRODUCTION",
        { domain: "expense.aero", expires: 1, path: "/" },
      );

      api.defaults.headers = {
        ...api.defaults.headers,
        Authorization: `${user.token_type} ${user.access_token}`,
      };
      api.defaults.headers.common = {
        ...api.defaults.headers.common,
        "Content-Type": "application/json; charset=utf-8",
      };
    } else {
      Cookies.remove("isLoggedIn");
      await authService.login();
    }
  }

  let userDetailMapper = new UserDetailMapper();
  let currentUser = await getCurrentEmployee();
  accessDenied = widgetMode
    ? currentUser.status === 401 || currentUser.status === 403
    : currentUser.status === 403;

  if (currentUser.headers.success) {
    let currentUserDetail = userDetailMapper.responseToEntity(currentUser.data);
    store.dispatch(setUserDetail(currentUserDetail));

    if (["ru", "en"].indexOf(currentUserDetail.language.code) !== -1) {
      await i18n.changeLanguage(currentUserDetail.language.code);
    } else {
      await i18n.changeLanguage("ru");
    }

    let showOnBoarding = find(currentUserDetail.employeeViewOptions, {
      paramName: "showonboarding",
    });

    if (showOnBoarding && showOnBoarding.value === "False") {
      store.dispatch(setOnboardModal(false));
    } else {
      store.dispatch(setOnboardModal(true));
    }
  } else {
    store.dispatch(setUserDetail(userDetailMapper.responseToEntity({})));
  }

  if (accessDenied) {
    appStart();
    return;
  }

  await Config.init();

  let attachmentPermission = await getAttachmentPermission();
  let manualExpenseCurrencyChange = await getManualExpenseCurrencyChange();
  let companies = await getCompanies();

  if (attachmentPermission.headers.success) {
    const permissionForExpenseApplication = find(
      attachmentPermission.data.data,
      { objectTypeId: "ExpenseApplication" },
    );
    const permissionForAdvanceReport = find(attachmentPermission.data.data, {
      objectTypeId: "AdvanceReportApplication",
    });
    let permission = {
      isExpenseApplicationAttachmentPermission: permissionForExpenseApplication
        ? get(permissionForExpenseApplication, "action")
        : true,
      isAdvanceReportAttachmentPermission: permissionForAdvanceReport
        ? get(permissionForAdvanceReport, "action")
        : true,
    };

    store.dispatch(setAttachmentPermission(permission));
  }

  if (manualExpenseCurrencyChange.headers.success) {
    const permissionZNR = find(manualExpenseCurrencyChange.data.data, {
      objectTypeId: "ExpenseApplication",
    });
    const permissionAO = find(manualExpenseCurrencyChange.data.data, {
      objectTypeId: "AdvanceReportApplication",
    });
    let permission = {
      isZNRManualExpenseCurrencyPermission: permissionZNR
        ? get(permissionZNR, "action")
        : false,
      isAOManualExpenseCurrencyPermission: permissionAO
        ? get(permissionAO, "action")
        : false,
    };

    store.dispatch(setManualExpenseCurrencyPermission(permission));
  }

  if (companies.headers.success) {
    const companiesList = get(companies, "data.data", []);
    store.dispatch(setCompaniesList(companiesList));
  }

  appStart();
};

const appStart = () => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <I18nextProvider i18n={i18n}>
          <App accessDenied={accessDenied} widgetMode={widgetMode} />
        </I18nextProvider>
      </HashRouter>
    </Provider>,
    document.getElementById("root"),
  );
};

if (
  window.location.href ===
  `${window.location.origin}/FederationMetadata/2007-06/FederationMetadata.xml`
) {
  ReactDOM.render(<FederationMetadata />, document.getElementById("root"));
} else if (authService.signin()) {
  preStart();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
