import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SvgIconS from "app/component/svg-icon/SvgIcon";
import { updateConsumptionType, updatePaymentForm } from "store/common/actions";
import { IAO, ScannedChequeInfoItemType } from "infrastructure/interfaces";
import {
  attachmentCheckId,
  getAdvanceReportApplication,
  getCommonConsumptionTypes,
  getCommonPaymentForms,
  isSideModal,
} from "../../../store/selectors";
import SlideModal from "../modal/SideModal";
import {
  Body,
  BodyTextarea,
  BodyTitle,
  ButtonBack,
  ButtonSave,
  Footer,
  Header,
  HeaderTitle,
} from "./AttachmentFilesQRScannerStyled";
import SelectWithSection2, {
  SelectWithSectionOptionType,
} from "../select/SelectWithSection2";
import AttachmentFilesChequeInfo from "./AttachmentFilesChequeInfo";
import { getСhequeInfoDetail } from "store/selectors";
import {
  checkIsChequeNotionEnabled,
  setScannedChequeInfo,
  setSideModal,
} from "store/qrScanner/actions";
import { NoticeItem } from "../notice/NoticePanel2";
import {
  bindChequeItemsToExpense,
  postExpenses,
  putAttachmentsById,
  updateExpensesApiById,
} from "services/ApiService";
import { updateDetailedAO } from "store/report/actions";
import { AttachmentItem } from "infrastructure/interfaces";
import { showErrors } from "store/exception/actions";

interface Props {
  attachmentItem: AttachmentItem;
}

const AttachmentFilesQRCanner: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const advanceReport = useSelector(getAdvanceReportApplication);
  const advanceReportDetail: IAO =
    useSelector(getAdvanceReportApplication) || ({} as IAO);
  const consumptionTypes = useSelector(getCommonConsumptionTypes);
  const chequeInfo = useSelector(getСhequeInfoDetail);
  const sideModal = useSelector(isSideModal);
  const paymentForms = useSelector(getCommonPaymentForms);
  const attachmentId = useSelector(attachmentCheckId);

  const [consumptionTypesOptions, setConsumptionTypesOptions] = useState<
    { value: number; text: string }[]
  >([]);
  const [consumptionType, setConsumptionType] =
    useState<SelectWithSectionOptionType | null>(null);
  const [expensesOptions, setExpensesOptions] = useState<
    { value: number; text: string }[]
  >([]);
  const [expense, setExpense] = useState<SelectWithSectionOptionType | null>(
    null,
  );
  const [description, setDescription] = useState<string | null>(null);
  const [paymentFormOptions, setPaymentFormOptions] = useState<
    { value: number; text: string }[]
  >([]);
  const [paymentForm, setPaymentForm] =
    useState<SelectWithSectionOptionType | null>(null);
  const [saveButtonIsClicked, setSaveButtonIsClicked] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const getAllExpensesOptions = () => {
    const additionalOptions = [
      { value: 1, text: t("QR.new_expense") }, // создать новый расход
    ];

    const expensesOptions: { value: number; text: string }[] =
      advanceReportDetail.expenses
        .filter(
          (elem) =>
            elem.paymentFormId.id !== 3 &&
            elem.expenseType.expenseGroup.code !== "Perdiem",
        )
        .map((el) => ({
          value: el.id,
          expenseTypeID: el.expenseType.id,
          text:
            el.description.charAt(0).toUpperCase() + el.description.slice(1),
          section: el.amountRub,
        }));
    const allOptions = [...additionalOptions, ...expensesOptions];
    setExpensesOptions(allOptions);
  };

  const addDescription = () => {
    let description =
      chequeInfo &&
      chequeInfo.qrCheck.qrCheckItems
        .map((el: ScannedChequeInfoItemType) => el.name)
        .join(", ");
    setDescription(description);
  };

  const addPaymentForm = () => {
    let paymentForm
	if (chequeInfo && chequeInfo.qrCheck.cashTotalSum) {
		paymentForm = paymentForms.find((el) => el.id === 1)
	} else {
		paymentForm = paymentForms.find((el) => el.id === 2)
		if (!paymentForm) {
			paymentForm = paymentForms.find((el) => el.id === 4)
		}
		if (!paymentForm) {
			paymentForm = paymentForms.find((el) => el.id === 3)
		}
	}
	if (!paymentForm) {
		setPaymentForm(null)
	} else {
		setPaymentForm({value: paymentForm.id, text: paymentForm.name[i18n.language as "ru" | "en"]});
	}
  };

  const getPaymentFormOptions = () => {
    let paymentFormsfromAPI = paymentForms
      .map((elem) => {
        return { value: elem.id, text: elem.name[i18n.language as "ru"] };
      });
    setPaymentFormOptions(paymentFormsfromAPI);
  };

  function checkVatTypes(array: Array<{ vatType: string }>) {
    return array.some((element, index) => {
      return array.slice(index + 1).some((nextElement) => {
        return nextElement.vatType !== element.vatType;
      });
    });
  }

  useEffect(() => {
    if (chequeInfo) {
      addDescription();
      addPaymentForm();
    }
  }, [chequeInfo, paymentForms]);

  useEffect(() => {
    if (paymentForms.length) {
      getPaymentFormOptions();
    }
  }, [paymentForms, consumptionType]);

  useEffect(() => {
    if (consumptionTypes.length) {
      setConsumptionTypesOptions(
        consumptionTypes //без суточных
          .filter((elem) => elem.expenseGroup.code !== "Perdiem")
          .map((el) => ({
            value: el.id,
            text: el.name[i18n.language as "ru"],
          })),
      );
    }
  }, [consumptionTypes]);

  useEffect(() => {
    getAllExpensesOptions();
  }, [advanceReportDetail.expenses]);

  useEffect(() => {
    if (expense && expense.value !== 1 && expense.expenseTypeID) {
      //выбрали существующий расход - отображаем форму оплаты
      dispatch(
        updatePaymentForm(
          advanceReport.applicationType.id,
          expense.expenseTypeID,
        ),
      );
    }

    if (expense && expense.value === 1 && consumptionType) {
      //выбрали новый расход + выбрали тип нового расхода - отображаем форму оплаты
      dispatch(
        updatePaymentForm(
          advanceReport.applicationType.id,
          consumptionType.value,
        ),
      );
    }

    if (expense && expense.value === 1) {
      // если выбран "новый расход", получаем все типы расходов и отображаем поле
      dispatch(
        updateConsumptionType(
          advanceReport.applicationType.id,
          advanceReport ? advanceReport.company.id : undefined,
        ),
      );
    }
  }, [expense, consumptionType]);

  const validator = () => {
    if (
      (expense && expense.value === 1 && consumptionType && description) ||
      (expense && expense.value !== 1 && description) ||
      (expense && expense.value !== 1 && description)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const attachFileToExpense = (expenseId: any) => {
    let formData = new FormData();
    formData.append("Content", "");
    formData.append("ObjectId.Id", expenseId);
    formData.append("ObjectId.LogicalName", "Expense");

    putAttachmentsById(attachmentId, formData).then(() => {
      dispatch(updateDetailedAO(advanceReport.id));
    });
  };

  const bindToExpense = async (expenseId: number) => {
    if (chequeInfo && expense) {
      const arrayOfCheckItems = {
        qrCheckItems: chequeInfo.qrCheck.qrCheckItems.map((el) => el.id),
      };
      await bindChequeItemsToExpense(expenseId, arrayOfCheckItems);
      attachFileToExpense(expenseId);
    }
  };

  const addNewExpense = async (data: {}) => {
    setIsSaving(true);
    let response = await postExpenses(data);
    if (response.headers.success) {
      bindToExpense(response.data.id);
      dispatch(updateDetailedAO(advanceReport.id));
      setSaveButtonIsClicked(false);
      dispatch(setSideModal(false));
    } else {
      setSaveButtonIsClicked(false);
      dispatch(setSideModal(false));
      dispatch(
        showErrors({
          code: "",
          message: response.data.Errors[0],
        }),
      );
    }
    setIsSaving(false);
  };

  const editExpense = async (expenseID: number, data: {}) => {
    let response = await updateExpensesApiById(expenseID, data);
    if (response.headers.success) {
      bindToExpense(expenseID);
      dispatch(updateDetailedAO(advanceReport.id));
      setSaveButtonIsClicked(false);
      dispatch(setSideModal(false));
    } else {
      setSaveButtonIsClicked(false);
      dispatch(setSideModal(false));
      dispatch(
        showErrors({
          code: "",
          message: response.data.Errors[0],
        }),
      );
    }
  };

  const saveHandler = () => {
    if (!validator()) {
      setSaveButtonIsClicked(true);
    } else {
      if (expense && chequeInfo && paymentForm) {
        const data = {
          applicationId: {
            id: advanceReport.id,
            logicalName: "AdvanceReportApplication",
          },
          amountCurrencyId: 1,
          amountRub: chequeInfo.qrCheck.amount,
          amount: chequeInfo.qrCheck.amount,
          paymentFormId: paymentForm.value,
          description: description,
          billDate: chequeInfo.qrCheck.operationDate,
          billNumber: chequeInfo.qrCheck.fiscalSign,
          expenseTypeId: consumptionType
            ? consumptionType.value
            : expense.expenseTypeID,
          consumptionType: {
            value: consumptionType
              ? consumptionType.value
              : expense.expenseTypeID,
            text: consumptionType ? consumptionType.text : expense.text,
          },
          vatAmount: chequeInfo.qrCheck.vatAmount,
          priceWithoutVAT:
            chequeInfo.qrCheck.amount - chequeInfo.qrCheck.vatAmount,
          vatRate: checkVatTypes(chequeInfo.qrCheck.qrCheckItems)
            ? t("QR.combined")
            : chequeInfo.qrCheck.qrCheckItems[0].vatType,
          populateDefaultCustomProperties: true, // только при распознавании QR-кода, передаем true
        };
        if (isSaving) {
          return;
        }
        if (expense && expense.value === 1 && !isSaving) {
          //создать новый расход на основании расхода из чека
          addNewExpense(data);
        }
        if (expense && expense.value !== 1) {
          //прикрепить расходы из чека к существующему расходу
          editExpense(expense.value, data);
        }
      }
    }
  };

  const cancelHandler = () => {
    let message: NoticeItem = {
      noticeIconID: "alertTriangle",
      color: "#FA5252",
      noticeTitle: t("QR.modal.cancel_cheque_info_title"),
      noticeText: t("QR.modal.recognize_body"),
      actionButton: {
        text: t("QR.modal.cancel_cheque_info_button_do"),
        color: "#FA5252",
        func: () => {
          dispatch(checkIsChequeNotionEnabled(false, null));
          dispatch(setScannedChequeInfo(null));
          dispatch(setSideModal(false));
        },
      },
      actionButton2: {
        text: t("QR.modal.cancel_cheque_info_button_donot"),
        func: () => dispatch(checkIsChequeNotionEnabled(false, null)),
      },
    };
    dispatch(checkIsChequeNotionEnabled(true, message));
  };

  return (
    <Fragment>
      <SlideModal isOpen={sideModal}>
        <Header>
          <HeaderTitle>{t("QR.expense_from_receipt_title")}</HeaderTitle>

          <SvgIconS
            className="icon icon-close"
            href="#svg_icon_close"
            onClick={cancelHandler}
            style={{ width: "16px", cursor: "pointer" }}
          />
        </Header>
        <Body>
          <BodyTitle>
            {t("QR.expense_from_receipt_bind")}
            <i className="input-required">*</i>
          </BodyTitle>
          <SelectWithSection2
            error={saveButtonIsClicked && !expense}
            options={expensesOptions}
            selectedOption={expense}
            setSelectedOption={setExpense}
          />
          {expense && expense.value === 1 && (
            <>
              <BodyTitle>
                {t("QR.expense_from_receipt_type")}
                <i className="input-required">*</i>
              </BodyTitle>
              <SelectWithSection2
                error={saveButtonIsClicked && !consumptionType}
                options={consumptionTypesOptions}
                selectedOption={consumptionType}
                setSelectedOption={setConsumptionType}
              />
            </>
          )}
          {consumptionType && paymentFormOptions && (
            <>
              <BodyTitle>{t("QR.expense_from_receipt_payForm")}</BodyTitle>
              <SelectWithSection2
                options={paymentFormOptions}
                selectedOption={paymentForm}
                setSelectedOption={setPaymentForm}
              />
            </>
          )}

          {chequeInfo && (
            <>
              <BodyTitle>
                {t("QR.expense_from_receipt_description")}
                <i className="input-required">*</i>
              </BodyTitle>
              <BodyTextarea
                error={saveButtonIsClicked && !description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                value={description || ""}
              />
            </>
          )}
          <AttachmentFilesChequeInfo checkVatTypes={checkVatTypes} />
          <div style={{ height: "83px" }} />
        </Body>
        <Footer>
          <ButtonBack onClick={cancelHandler}>{t("QR.cancel")}</ButtonBack>
          <ButtonSave onClick={saveHandler}>{t("QR.save")}</ButtonSave>
        </Footer>
      </SlideModal>
    </Fragment>
  );
};

export default AttachmentFilesQRCanner;
